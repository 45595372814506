import { Controller } from 'react-hook-form';
import FormTextInput from 'app/components/ProcureForm/FormTextInput';
import Orders from 'app/i18n/Orders';
import { FeatureFlagEnum, useFeatureFlag } from 'app/libs/featureFlag';

import useInsertItemChange from '../../hook/useInsertItemChange';
import { ORDER_INSERT_TYPE, OrderFieldProps } from '../../type';
import { ORDER_REQUEST_FORM_RULES } from '../../utils/rules';

const OrderDescriptionField = (props: OrderFieldProps) => {
  const { formState, disabled, isExternalTenant } = props;
  const { control } = formState;

  const { value: isDescriptionFieldEnabled, loading } = useFeatureFlag(
    FeatureFlagEnum.DescriptionFieldEnabledInOrderRequestForm,
  );

  const { onInsertItemChange, setInputRef, isInsertItemTypeSame } = useInsertItemChange({
    insertItemType: ORDER_INSERT_TYPE.NON_STOCKED_ITEMS,
    formState,
  });

  if (loading || !isDescriptionFieldEnabled || isExternalTenant) {
    return null;
  }

  return (
    <div className="flex">
      <div className="flex-1 flex-auto">
        <Controller
          control={control}
          name="description"
          render={({ field, fieldState }) => (
            <>
              <FormTextInput
                {...field}
                multiline
                disabled={disabled}
                characterLimit={500}
                getInputReference={setInputRef}
                label={Orders.FormLabels.Description}
                rows={1}
                // onFocus={onInsertItemChange}
                formLabel={Orders.FormLabels.Description}
              />
              {fieldState.error && (
                <span className="mt-3 text-[14px] flex text-[#db483e] capitalize mr-15">
                  {fieldState.error.message}
                </span>
              )}
            </>
          )}
          rules={ORDER_REQUEST_FORM_RULES.description(formState.actions.validateAllEmpty)}
        />
      </div>
    </div>
  );
};

export default OrderDescriptionField;
