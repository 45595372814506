import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import FormTextInput from 'app/components/ProcureForm/FormTextInput';
import Common from 'app/i18n/Common';
import Wishlist from 'app/i18n/Wishlist';
import Box from 'app/ui-components/Box';

function StepTwo(props: any) {
  const { isLoading, onNext, onPrevious, formValues } = props;
  const defaultValues = {
    description: formValues.description,
  };
  const { control, handleSubmit } = useForm({ defaultValues: defaultValues });
  const onFormSubmit = useMemo(() => handleSubmit(onNext), [handleSubmit, onNext]);

  return (
    <form data-testid="create-list-form">
      <Box className="mx-auto mt-12 min-w-[120px] max-w-[487px] space-y-24">
        <label className="font-semibold text-grey-900">
          {Wishlist.CreateList.CreateListMessage}
        </label>
        <div style={{ marginTop: '8px', marginBottom: '18px' }}>
          <Controller
            control={control}
            name="description"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                characterLimit={500}
                className="w-full rounded-none !border-[0px] !p-[0px]"
                error={fieldState.error}
                label={Wishlist.FormLabels.Message}
                multiline={true}
              />
            )}
          />
        </div>
        <Stack>
          <Box className="flex justify-end space-x-[16px]">
            <Button block={true} onClick={onPrevious}>
              {Common.Actions.Previous}
            </Button>
            <Button block={true} loading={isLoading} theme="info" onClick={onFormSubmit}>
              {Common.Actions.Next}
            </Button>
          </Box>
        </Stack>
      </Box>
    </form>
  );
}

export default StepTwo;
