import { useCallback, useEffect, useState } from 'react';
import { UseEntityManagerReturnType } from 'app/modules/components/EntityManager/useEntityManager';

import useFindUPC from '../context/useFindUPC';
import { useFindUpcQuery } from '../graphql/query/generated/comparisonShopProductsForFindUPC';

interface State {
  products: SearchProduct[];
  loading: boolean;
  totalCount: number;
  productDetailModal: {
    open: boolean;
    selectedProduct?: SearchProduct;
  };
}

interface SearchProduct {
  id: string;
  name: string;
  modelName: string;
  manufacturer: string;
  globalTradeItemNumber: string;
  universalProductCode: string;
  productCode: string;
  productCodeType: string;
  description: string;
  images: string[];
}

const parseProductNodes = (data: any): SearchProduct[] => {
  return data?.comparisonShopProductsForFindUPC?.map((product: any, index: number) => ({
    id: `${product.upc}-${index}`,
    name: product.title,
    modelName: product.model || '',
    manufacturer: product.manufacturer || '',
    globalTradeItemNumber: product.gtin || '',
    universalProductCode: product.upc || '',
    productCode: product.productCode || '',
    productCodeType: product.productCodeType || '',
    description: product.shortDescription || product.longDescription || '',
    images: product.images?.thumbnails || [],
  })) || [];
};

const useFindUpcState = () => {
  const [state, _setState] = useState<State>({
    loading: false,
    products: [],
    totalCount: 0,
    productDetailModal: {
      open: false,
      selectedProduct: undefined,
    },
  });

  const { search, table } = useFindUPC() as UseEntityManagerReturnType;

  const variables = {
    search: search.debouncedSearchText || '',
    asins: [search.debouncedSearchText || ''],
    isbns: [search.debouncedSearchText || ''],
    upcs: [search.debouncedSearchText || ''],
  };

  const [{ data, fetching, error }] = useFindUpcQuery({
    variables,
    pause: !search.debouncedSearchText,
  });

  useEffect(() => {
    if (fetching) {
      setState({ loading: true });
    } else if (error) {
      setState({ loading: false });
    } else {
      setState({
        products: parseProductNodes(data),
        loading: false,
        totalCount: data?.comparisonShopProductsForFindUPC?.length || 0,
      });
    }
  }, [data, fetching, error]);

  const setState = useCallback((nextState: Partial<State>) => {
    _setState((prevState) => ({ ...prevState, ...nextState }));
  }, []);

  const fetchMore = useCallback(() => {
    table.setState({
      activePage: table.state.activePage + 1,
    });
  }, [table.state.activePage, table.setState]);

  const onProductCodeClick = useCallback(
    (productData: SearchProduct) => {
      setState({
        productDetailModal: {
          open: true,
          selectedProduct: productData,
        },
      });
    },
    [setState],
  );

  return {
    state,
    setState,
    search,
    table,
    fetchMore,
    onProductCodeClick,
  };
};

export type UseFindUpcStateReturnType = ReturnType<typeof useFindUpcState>;
export default useFindUpcState;
