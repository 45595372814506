import { Icon } from '@iconify/react';
import { Stack } from '@mui/material';
import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import routes from 'app/consts/routes';
import { NavLink } from 'app/libs/navigation';
import ReadMore from 'app/modules/components/ReadMore';
import { DateFormatEnum, formatDate } from 'app/utils/date';

import CopyToClipboard from '../../../components/CopyToClipboard';

export const getWishlistTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'name',
    label: 'Wishlist',
    sortable: true,
    valueNode: ({ row }) => {
      return (
        <NavLink className="sku-link" to={routes.EditWishlist(row.id)}>
          {row.name}
        </NavLink>
      );
    },
    value: 'name',
  },
  {
    id: 'description',
    label: 'Description',
    sortable: true,
    value: 'description',
    addReadMore: true,
  },
  {
    id: 'shippingAddress',
    label: 'Shipping Address',
    sortable: true,
    value: 'shippingAddress.address',
    width: 450,
    valueNode: ({ row }) => {
      const shippingAddress = Object.values(row.shippingAddress).join(' ');
      return (
        <Stack
          alignItems={'center'}
          direction="row"
          justifyContent="space-between"
          style={{ width: '440px' }}>
          <div className="pt-[6px]">
            <ReadMore hideShowMoreBtn text={shippingAddress} />
          </div>
          <CopyToClipboard
            label="Shipping Address"
            text={Object.values(row.shippingAddress).join(' ')}
          />
        </Stack>
      );
    },
  },
  {
    id: 'createdAt',
    label: 'Created At',
    sortable: true,
    value: 'createdAt',
    valueNode: ({ row }) => <>{formatDate(row.createdAt, DateFormatEnum.FullDate)}</>,
  },
  {
    id: 'updatedAt',
    label: 'Last Updated',
    sortable: true,
    value: 'updatedAt',
    valueNode: ({ row }) => <>{formatDate(row.updatedAt, DateFormatEnum.FullDate)}</>,
  },
  {
    id: 'eventDate',
    label: 'Event Date',
    sortable: true,
    value: 'eventDate',
    valueNode: ({ row }) => (
      <Stack direction={'row'} spacing={2}>
        <>{formatDate(row.eventDate, DateFormatEnum.Date)}</>
        {new Date(row.eventDate).getTime() < new Date().getTime() && (
          <Icon color="red" height="10%" icon="jam:alert" width="10%" />
        )}
      </Stack>
    ),
  },
  {
    id: 'shareId',
    label: 'Copy Link',
    value: 'shareId',
    valueNode: ({ row }) => (
      <CopyToClipboard
        isButton
        label="Link"
        text={`${process.env.REACT_APP_CORE_UI_URL}${routes.WishlistShareWithId(row.shareId)}`}
      />
    ),
  },
];
