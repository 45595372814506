import { forwardRef, useCallback, useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { cx } from '@emotion/css';
import { Input } from '@mui/material';
import { Maybe } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import FormError from '../FormError';
import FormLabel from '../FormLabel';

interface Props {
  className?: string;
  inputWrapperClass?: string;
  label?: string;
  isRequired?: boolean;
  name?: string;
  value?: Maybe<string>;
  disabled?: boolean;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  error?: FieldError;
  placeholder?: string;
  rows?: number;
  characterLimit?: number;
  onKeyDown?: any;
  defaultTextAreaHeight?: string;
  isResizable?: boolean;
  id?: string;
  inputStyle?: any;
}

const FormTextArea = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    className,
    error,
    label,
    isRequired,
    value,
    disabled,
    rows = 4,
    placeholder,
    onChange,
    characterLimit,
    name,
    inputWrapperClass,
    onKeyDown,
    onBlur,
    isResizable = true,
    defaultTextAreaHeight = '96px',
    id = '',
    inputStyle,
  } = props;

  const [isFocused, setIsFocused] = useState(false);


  const trimmedValue = useMemo(() => {
    return characterLimit ? value?.toString().slice(0, characterLimit) : value;
  }, [value, characterLimit]);


  const onChangeTextArea = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const text = event.target.value;
      onChange?.(text);
    },
    [onChange],
  );

  const onFocus = () => setIsFocused(true);
  const onBlurHandle = () => {
    setIsFocused(false);
    onBlur?.();
  };

  return (
    <Box ref={ref} className={cx('flex flex-col', className)}>
      {label ? <FormLabel isRequired={isRequired}>{label}</FormLabel> : null}
      <Box
        className={`min-h-62 relative mt-6 flex rounded-[8px] border p-[2px] ${
          isFocused ? 'border-blue-900' : 'border-grey-500'
        }
          ${disabled ? 'bg-grey-200' : ''}
          ${inputWrapperClass || ''} `}>
        <Input
          multiline
          aria-label={name}
          className="flex-1 !border-0 !bg-[white] !p-0"
          componentsProps={{
            input: {
              maxLength: characterLimit,
              className: 'py-[12px] px-[12px] border border-grey-300',
              style: {
                ...inputStyle,
                resize: `${isResizable && !disabled ? 'block' : 'none'}`,
                minHeight: defaultTextAreaHeight,
                overflow: `auto`,
              } as React.CSSProperties,
            },
          }}
          disabled={disabled}
          endAdornment={
            characterLimit ? (
              <span className="absolute right-[15px] bottom-[5px] mx-8 self-end text-[12px] font-semibold text-grey-800">
                {trimmedValue?.toString().length}/{characterLimit}
              </span>
            ) : null
          }
          id={id}
          name={name}
          placeholder={placeholder}
          // rows={rows}
          value={trimmedValue || ''}
          onBlur={onBlurHandle}
          onChange={onChangeTextArea}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
        />
      </Box>
      <FormError error={error} />
    </Box>
  );
});

export default FormTextArea;
