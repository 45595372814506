import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Box } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import { isEmpty } from 'lodash';
import FormTextInput from 'app/components/Form/FormTextInput';
import FormOrderSelect from 'app/components/ProcureForm/FormOrderSelect';
import Receivables from 'app/i18n/Receivables';
import { RECEIVE_FORM_RULES } from 'app/modules/receiving/view/utils/validate';

import FormTextArea from '../../../../../../../components/Form/FormTextArea';

const ScheduleReceiveForm = (props: any) => {
  const { formState, setFormState, onAdd } = props;
  const { handleSubmit, control } = formState;

  const onFormSubmit = useMemo(() => handleSubmit(onAdd), [onAdd, handleSubmit]);

  const handleOrderIdChange = (option: any) => {
    setFormState({
      ...formState.state,
      orderRequest: {
        title: option?.title,
        deliverTo: { name: option?.deliverTo?.name },
        orderRequestCode: option?.orderRequestCode,
      },
    });
  };

  return (
    <>
      <div className="mt-20">
        <form onSubmit={onFormSubmit}>
          <Box className="flex flex-col gap-[24px]">
            <Controller
              control={control}
              name="orderRequestId"
              render={({ field, fieldState }) => (
                <FormOrderSelect
                  {...field}
                  isRequired
                  paginated
                  error={fieldState.error}
                  formLabel={Receivables.FormLabels.procureOrderId}
                  onChange={(value, valueObj) => {
                    field.onChange(value);
                    handleOrderIdChange(valueObj);
                  }}
                />
              )}
              rules={RECEIVE_FORM_RULES.procureOrderId}
            />
            <FormTextInput
              isDisabled
              label={Receivables.FormLabels.procureOrderTitle}
              testId="procure-order-title"
              value={formState.state.orderRequest?.title}
            />
            <FormTextInput
              isDisabled
              label={Receivables.FormLabels.deliverTo}
              testId="deliver-to"
              value={formState.state.orderRequest?.deliverTo?.name}
            />
            <Controller
              control={control}
              name="notes"
              render={({ field, fieldState }) => (
                <FormTextArea
                  {...field}
                  characterLimit={30}
                  defaultTextAreaHeight={'23px'}
                  isResizable={false}
                  error={fieldState.error}
                  label={Receivables.FormLabels.notes}
                />
              )}
            />
          </Box>
          <div className="mt-[24px] flex flex-auto justify-end gap-[16px]">
            <Button
              classes="min-w-[94px] h-[44px]"
              disabled={!isEmpty(formState.errors) || formState.isValidatingSelectedOrderId}
              theme="success"
              onClick={onFormSubmit}>
              {Receivables.Actions.AddShipment}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ScheduleReceiveForm;
