import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FindUpcQueryVariables = Types.Exact<{
  search: Types.Scalars['String'];
  asins: Array<Types.Scalars['String']> | Types.Scalars['String'];
  isbns: Array<Types.Scalars['String']> | Types.Scalars['String'];
  upcs: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type FindUpcQuery = { __typename?: 'Query', comparisonShopProductsForFindUPC: Array<{ __typename?: 'ComparisonShopProductSchema', title: string, shortDescription?: string | null, longDescription?: string | null, listPrice?: number | null, currency?: string | null, manufacturer?: string | null, model?: string | null, merchantSKU: string, merchant: Types.ComparisonShopProductMerchantEnum, upc: string, affiliateLink: string, category?: string | null, productCode: string, productCodeType?: Types.ItemExternalProductCodeTypeEnum | null, gtin: string, images: { __typename?: 'ComparisonShopProductImageSchema', thumbnails?: Array<string> | null, mediumSizes: Array<string>, largeSizes: Array<string> } }> };


export const FindUpcDocument = gql`
    query findUpc($search: String!, $asins: [String!]!, $isbns: [String!]!, $upcs: [String!]!) {
  comparisonShopProductsForFindUPC(
    filters: {search: $search, asins: $asins, isbns: $isbns, upcs: $upcs}
  ) {
    title
    shortDescription
    longDescription
    listPrice
    currency
    manufacturer
    model
    merchantSKU
    merchant
    upc
    images {
      thumbnails
      mediumSizes
      largeSizes
    }
    affiliateLink
    category
    productCode
    productCodeType
    gtin
  }
}
    `;

export function useFindUpcQuery(options: Omit<Urql.UseQueryArgs<FindUpcQueryVariables>, 'query'>) {
  return Urql.useQuery<FindUpcQuery>({ query: FindUpcDocument, ...options });
};