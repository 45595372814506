import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, CheckBox } from '@procurenetworks/procure-component-library';
import Assets from 'app/i18n/Assets';

import FormTextArea from '../../../../../components/Form/FormTextArea';
import FormTextInput from '../../../../../components/Form/FormTextInput';
import Modal from '../../../../../components/Modal';
import Common from '../../../../../i18n/Common';
import Inventory from '../../../../../i18n/Inventory';
import { UseFindUpcStateReturnType } from '../../hook/useFindUpcState';
import FormImageSelect from '../FormImageSelect';

interface Props {
  state: UseFindUpcStateReturnType['state'];
  setState: UseFindUpcStateReturnType['setState'];
  onSubmit: (values: any) => void;
}

const ProductDetailFormModal = (props: Props) => {
  const { state, setState, onSubmit } = props;

  const selectedProduct = state.productDetailModal.selectedProduct;

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: selectedProduct
      ? {
          productCode: selectedProduct.productCode,
          productCodeType: selectedProduct.productCodeType,
          title: selectedProduct.name.substring(0, 32) || '',
          model: selectedProduct.modelName || '',
          description: selectedProduct.description || '',
          brand: selectedProduct.manufacturer || '',
          images: [],
          //Fields Having Select in their Name are for checkboxes
          titleSelect: false,
          descriptionSelect: false,
          modelSelect: true,
          brandSelect: true,
          productCodeSelect: true,
          productCodeTypeSelect: true,
        }
      : {},
  });

  const onCancel = useCallback(() => {
    setState({
      productDetailModal: {
        selectedProduct: undefined,
        open: false,
      },
    });
  }, [setState]);

  const selectFormValuesBasedOnUserSelection = (modalValues: any) => {
    const parsedModalValues: Record<string, string> = {};
    for (const fieldName in modalValues) {
      if (!fieldName.includes('Select')) {
        if (modalValues[`${fieldName}Select`] === true) {
          parsedModalValues[fieldName] = modalValues[fieldName];
        } else if (
          fieldName === 'images' &&
          modalValues[fieldName] &&
          modalValues[fieldName].length
        ) {
          parsedModalValues[fieldName] = modalValues[fieldName];
        }
      }
    }
    return parsedModalValues;
  };

  const onFormSubmit = useMemo(
    () =>
      handleSubmit((values) => {
        const updatedValuesFromUserSelection: Record<string, string> =
          selectFormValuesBasedOnUserSelection(values);
        onCancel();
        onSubmit(updatedValuesFromUserSelection);
      }),
    [handleSubmit, onSubmit, onCancel],
  );

  return (
    <Modal
      actions={
        <div>
          <Button theme="success" onClick={onFormSubmit}>
            {Assets.Actions.AddProductDetails}
          </Button>
          <Button classes="ml-[4px]" onClick={onCancel}>
            {Common.Actions.Cancel}
          </Button>
        </div>
      }
      id="hoover-product-detail-modal"
      open={state.productDetailModal.open}
      title={Inventory.EditHooverProductDetails}
      onClose={onCancel}>

      <p className='text-[16px] text-green-800 mt-[16px]'>{Common.FieldSelectMessage}</p>
      <div className="w-[100%] md:w-[560px]">
        <form>
          <div className="mb-20 mt-10">
            <div className="flex">
              <div className="mt-20">
                <Controller
                  control={control}
                  name="productCodeSelect"
                  render={() => <CheckBox disabled={true} value={true} />}
                />
              </div>
              <div className="flex-1">
                <Controller
                  control={control}
                  name="productCode"
                  render={({ field, fieldState }) => (
                    <FormTextInput
                      {...field}
                      isDisabled
                      error={fieldState.error}
                      label={Inventory.FormLabels.UpcCode}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className="mb-20">
            <div className="flex">
              <div className="mt-20">
                <Controller
                  control={control}
                  name="titleSelect"
                  render={({ field: { onChange, value } }) => (
                    <CheckBox value={value || false} onChange={onChange} />
                  )}
                />
              </div>
              <div className="flex-1">
                <Controller
                  control={control}
                  name="title"
                  render={({ field, fieldState }) => (
                    <FormTextInput {...field} isDisabled label={Inventory.FormLabels.Title} />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="mb-20">
            <div className="flex">
              <div className="mt-20">
                <Controller
                  control={control}
                  name="modelSelect"
                  render={() => <CheckBox disabled={true} value={true} />}
                />
              </div>
              <div className="flex-1">
                <Controller
                  control={control}
                  name="model"
                  render={({ field, fieldState }) => (
                    <FormTextInput {...field} isDisabled label={Inventory.FormLabels.Model} />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="mb-20">
            <div className="flex">
              <div className="mt-20">
                <Controller
                  control={control}
                  name="descriptionSelect"
                  render={({ field: { onChange, value } }) => (
                    <CheckBox value={value || false} onChange={onChange} />
                  )}
                />
              </div>
              <div className="flex-1">
                <Controller
                  control={control}
                  name="description"
                  render={({ field, fieldState }) => (
                    <FormTextArea {...field} disabled label={Inventory.FormLabels.Description} characterLimit={500} />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="mb-20">
            <div className="flex">
              <div className="mt-20">
                <Controller
                  control={control}
                  name="brandSelect"
                  render={() => <CheckBox disabled={true} value={true} />}
                />
              </div>
              <div className="flex-1">
                <Controller
                  control={control}
                  name="brand"
                  render={({ field, fieldState }) => (
                    <FormTextInput {...field} isDisabled label={Inventory.FormLabels.Brand} />
                  )}
                />
              </div>
            </div>
          </div>

          {(selectedProduct?.images || []).length > 0 ? (
            <div className="mb-20">
              <Controller
                control={control}
                name="images"
                render={({ field, fieldState }) => (
                  <FormImageSelect
                    {...field}
                    isDisabled
                    images={selectedProduct?.images || []}
                    label={Inventory.FormLabels.Image}
                  />
                )}
              />
            </div>
          ) : null}
        </form>
      </div>
    </Modal>
  );
};

export default ProductDetailFormModal;
