import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import usePersistAttachmentsHelper from 'app/modules/components/FindUPC/components/ProductDetailFormModal/hook/usePersistUpcProductData';

import useFormFileUploader from '../../../../../../components/ProcureForm/FormFileUploader/hook/useFormFileUploader';
import {
  CreateInventoryItemInput,
  CreateInventoryKitItemInput,
  CreateMediaInput,
  CreateRestockTransactionsInput,
  InventoryItemSchema,
  InventoryKitItemSchema,
  ItemTypeEnum,
  ItemUnion,
  LocationSchema,
  RestockTransactionSubTypeEnum,
  TransactionEntityTypeEnum,
  UpdateInventoryItemInput,
  UpdateInventoryKitItemInput,
} from '../../../../../../types/schema';
import { FindUpcContextType } from '../../../../../components/FindUPC/context/FindUPCProvider';
import useFindUPC from '../../../../../components/FindUPC/context/useFindUPC';
import {
  createInventoryItemInputDTO,
  createInventoryKitItemInputDTO,
  createUpdateInventoryItemInputDTO,
  createUpdateInventoryKitItemInputDTO,
} from '../../utils/dto';
import { INVENTORY_TYPE_ITEMS } from '../../utils/utils';

interface Props {
  defaultValues: any;
  itemData?: ItemUnion;
  inventoryId?: string;
  defaultEditable?: boolean;
  isEditMode?: boolean;
}

const useInventoryForm = (props: Props) => {
  const {
    inventoryId,
    defaultEditable = true,
    isEditMode = false,
    defaultValues,
    itemData,
  } = props;

  const [editable, setEditable] = useState<boolean>(defaultEditable);
  const [showKitSummary, setShowKitSummary] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [site, setSite] = useState<Partial<LocationSchema> | null>(null);

  useEffect(() => {
    setShowKitSummary(isEditMode && itemData?.type === ItemTypeEnum.InventoryKit);
  }, [itemData?.type, isEditMode]);

  const { control, handleSubmit, watch, setValue, reset, getValues, resetField, trigger } = useForm(
    {
      defaultValues: {
        ...defaultValues,
      },
    },
  );

  const attachments = watch('attachments');

  const { search: findUpcCodeSearch } = useFindUPC() as FindUpcContextType;

  const defaultAttachments = useMemo(() => {
    return (
      defaultValues?.attachments?.map((attachment: CreateMediaInput, index: number) => {
        return {
          id: `attachment-${index}`,
          ...attachment,
          resolutions: attachment?.resolutions || [],
        };
      }) || []
    );
  }, [defaultValues?.attachments]);

  const {
    inputProps: attachmentInputProps,
    setAttachments,
    clearAll,
    addFileFromUrl: addAttachmentFromUrl,
  } = useFormFileUploader('attachments', defaultAttachments);
  const { parseNoUpcAssetAttachments, parseProduceDataImagesToAssetAttachments } =
    usePersistAttachmentsHelper(attachmentInputProps);

  const resetFormData = useCallback(() => {
    reset({
      ...props.defaultValues,
    });
    clearAll();
    setAttachments(defaultAttachments);
  }, [reset, props.defaultValues, setAttachments, defaultAttachments]);

  useEffect(() => {
    if (!editable) {
      resetFormData();
    }
  }, [editable, setAttachments, defaultAttachments]);

  const itemType = watch('type');
  const upcCode = watch('upcCode');
  const noUpcCode = watch('noUpcCode');
  const destinationSiteId = watch('destinationSiteId');
  const destinationLocationId = watch('destinationLocationId');
  const showFindUpcSection = watch('findUpc');
  const quantity = watch('quantity');

  const formattedCost = useCallback(
    (cost: number | string) => (cost ? Number(cost)?.toFixed(2) : 0),
    [],
  );

  useEffect(() => {
    const _defaultValues = { ...defaultValues };
    delete _defaultValues?.type;
    reset({
      ...getValues(),
      ..._defaultValues,
    });
    clearAll();
    setAttachments(defaultAttachments);
    if (!inventoryId) {
      setShowKitSummary(false);
    }
  }, [defaultAttachments, inventoryId, itemType]);

  const updateUnitCost = useCallback(
    (cost: number) => {
      setValue('unitCost', formattedCost(cost));
    },
    [setValue],
  );

  const onNoUpcCodeChange = useCallback(
    (noUpcCodeValue: boolean) => {
      if (noUpcCodeValue) {
        setValue('findUpc', false);
        resetField('upcCode', { defaultValue: '' });
        parseNoUpcAssetAttachments();
      } else {
        setValue('upcCode', '');
      }
    },
    [setValue, setAttachments, resetField, parseNoUpcAssetAttachments],
  );

  useEffect(() => {
    setValue('destinationLocationId', '');
  }, [destinationSiteId]);

  const onFindUpcClick = useCallback(() => {
    setValue('findUpc', true);
    findUpcCodeSearch?.onChange(upcCode);
  }, [setValue, upcCode, findUpcCodeSearch?.onChange]);

  const onSaveProductData = useCallback(
    (values: any) => {
      const productImages = values?.images || [];
      parseProduceDataImagesToAssetAttachments(productImages);
      // Upload Product image Url to S3
      productImages.forEach((image: string) => {
        addAttachmentFromUrl(image);
      });

      reset({
        ...getValues(),
        ...values,
        noUpcCode: false,
        upcCode: values?.productCode || '',
        productCodeType: values?.productCodeType || '',
        description: values?.description || '',
        title: values?.title?.substring(0, 32) || '',
        mName: values?.model || '',
        brand: values?.brand || '',
      });
    },
    [
      reset,
      getValues,
      attachments,
      setAttachments,
      addAttachmentFromUrl,
      parseProduceDataImagesToAssetAttachments,
    ],
  );

  const isItemTypeProduct = useMemo(() => {
    return itemType === ItemTypeEnum.Inventory;
  }, [itemType]);
  const isItemTypeKit = useMemo(() => {
    return itemType === ItemTypeEnum.InventoryKit;
  }, [itemType]);

  const createInventoryItemInput = (values: any): CreateInventoryItemInput => {
    return createInventoryItemInputDTO(values, isItemTypeProduct);
  };

  const createInventoryKitItemInput = (
    values: any,
    kitItems: any[],
  ): CreateInventoryKitItemInput => {
    return createInventoryKitItemInputDTO(values, kitItems);
  };

  const createUpdateInventoryItemInput = (values: any): UpdateInventoryItemInput => {
    return createUpdateInventoryItemInputDTO(inventoryId || '', values, isItemTypeProduct);
  };
  const createUpdateInventoryKitItemInput = (values: any): UpdateInventoryKitItemInput => {
    return createUpdateInventoryKitItemInputDTO(inventoryId || '', values);
  };

  const createRestockTransaction = (values: any): CreateRestockTransactionsInput => {
    const { entityId, quantity, destinationSiteId, destinationLocationId } = values;
    return {
      entities: [
        {
          entityType: TransactionEntityTypeEnum.Inventory,
          entityId: entityId,
          destinationSiteId,
          destinationLocationId,
          quantity: Number(quantity),
        },
      ],
      subType: RestockTransactionSubTypeEnum.New,
    };
  };

  const onCancelKitSummary = useCallback(() => {
    setShowKitSummary(false);
    resetField('unitCost');
  }, [setShowKitSummary]);

  const onSaveKitData = useCallback(() => {
    setShowKitSummary(true);
  }, [setShowKitSummary]);

  const onCancelDelete = useCallback(() => {
    setShowDeleteConfirmation(false);
  }, [setShowDeleteConfirmation]);

  const onDeleteConfirmation = useCallback(() => {
    setShowDeleteConfirmation(true);
  }, [setShowDeleteConfirmation]);

  const makeEditable = useCallback(() => {
    setEditable(true);
    if (itemData?.type === ItemTypeEnum.InventoryKit) {
      setShowKitSummary(true);
    }
  }, [itemData?.type]);

  const cancelEditable = useCallback(() => {
    setEditable(false);
  }, [setEditable]);

  return {
    state: {
      control,
      defaultValues: props.defaultValues,
      itemData,
      inventoryItemTypes: INVENTORY_TYPE_ITEMS,
      noUpcCode,
      itemType,
      isEditMode,
      onFindUpcClick,
      destinationSiteId,
      destinationLocationId,
      showFindUpcSection,
      onSaveProductData,
      handleSubmit,
      isItemTypeProduct,
      isItemTypeKit,
      quantity,
      createInventoryItemInput,
      editable,
      makeEditable,
      cancelEditable,
      attachmentInputProps,
      showDeleteConfirmation,
      showKitSummary,
      onCancelDelete,
      onDeleteConfirmation,
      onSaveKitData,
      onCancelKitSummary,
      itemId: inventoryId,
      onNoUpcCodeChange,
      updateUnitCost,
      getValues,
      resetFormData,
      trigger,
      site,
      setSite,
    },
    createInventoryItemInput,
    createInventoryKitItemInput,
    createRestockTransaction,
    createUpdateInventoryItemInput,
    createUpdateInventoryKitItemInput,
  };
};

export type UseInventoryFormReturnType = ReturnType<typeof useInventoryForm>;
export default useInventoryForm;
