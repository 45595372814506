import { Controller } from 'react-hook-form';

import FormTextArea from '../../../../../../components/Form/FormTextArea';
import Inventory from '../../../../../../i18n/Inventory';
import { InventoryFormProps } from '../../types';

const InventoryDescriptionField = (props: InventoryFormProps) => {
  const { formState, disabled } = props;
  const { control } = formState;

  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="description"
        render={({ field, fieldState }) => (
          <FormTextArea
            {...field}
            characterLimit={500}
            disabled={disabled}
            error={fieldState.error}
            label={Inventory.FormLabels.Description}
          />
        )}
      />
    </div>
  );
};

export default InventoryDescriptionField;
